// src/components/ImageSlider.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles

import image1 from '../assets/images/image1.jpg'; // Import your images
import image2 from '../assets/images/image2.jpg';
import image3 from '../assets/images/image3.jpg';

function ImageSlider() {
  return (
    <div className="image-slider">
      <Carousel 
        showArrows={true} 
        autoPlay={true} 
        infiniteLoop={true} 
        showThumbs={false} 
        showStatus={false} 
        interval={3000} 
      >
        <div>
          <img src={image1} alt="A car being washed with foam" />
        </div>
        <div>
          <img src={image2} alt="Interior car cleaning service" />
        </div>
        <div>
          <img src={image3} alt="Exterior detailing on a car" />
        </div>
      </Carousel>
    </div>
  );
}

export default ImageSlider;
