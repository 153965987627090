// src/components/Navbar.js
import React, { useState } from 'react';
import { FaBars } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './Navbar.css';  // Ensure you create a corresponding CSS file
import logo from '../assets/images/Logo.png';  // Import the logo image

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img src={logo} alt="Car Spa Logo" />
        </Link>
      </div>
      <div className="navbar-title">
        <Link to="/">PLATINUM CAR SPA</Link>
      </div>
      <ul className={`navbar-links ${isOpen ? "active" : ""}`}>
        <li>
          <Link to="/" onClick={toggleMenu}>Home</Link>
        </li>
        <li>
          <Link to="/about" onClick={toggleMenu}>About</Link>
        </li>
        <li>
          <Link to="/services" onClick={toggleMenu}>Services</Link>
        </li>
        <li>
          <Link to="/contact" onClick={toggleMenu}>Contact</Link>
        </li>
      </ul>
      <div className="icon" onClick={toggleMenu}>
        <FaBars />
      </div>
    </nav>
  );
}

export default Navbar;
