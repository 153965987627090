// src/pages/About.js
import React from 'react';
import { useEffect } from 'react';
import './About.css';

function About() {

  useEffect(()=>{
    document.title = "About - Platinum Car Spa"
  })
  
  return (
    <div className ="main">
      
      <main className="about-content">
        <section className="about-intro">
          <h1>About Us</h1>
          <p>
            Welcome to Platinum Car Spa, your premier destination for car washing and detailing services. We are committed to providing top-notch services to keep your car looking its best.
          </p>
        </section>

        <section className="about-mission">
          <h2>Our Mission</h2>
          <p>
            At Platinum Car Spa, our mission is to deliver exceptional car care services that exceed our customers' expectations. We aim to provide a convenient, high-quality, and eco-friendly car wash experience.
          </p>
        </section>

        <section className="about-services">
          <h2>Our Services</h2>
          <ul>
            <li>Exterior Car Wash</li>
            <li>Interior Detailing</li>
            <li>Waxing and Polishing</li>
            <li>Tire Cleaning</li>
            <li>Engine Bay Cleaning</li>
          </ul>
        </section>

        <section className="about-team">
          <h2>Meet Our Team</h2>
          <p>
            Our team consists of experienced and dedicated professionals who are passionate about car care. We use the latest techniques and products to ensure your car receives the best treatment.
          </p>
        </section>

        <section className="about-unique">
          <h2>What Makes Us Unique</h2>
          <p>
            We stand out from the competition by offering personalized services, using eco-friendly products, and maintaining a customer-first approach. Your satisfaction is our priority.
          </p>
        </section>
      </main>
      </div>
  );
}

export default About;
