// Footer.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import services from '../data/services';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <footer>
      <div className="footer-content">
        <div className="service-container">
          <h2 className={`service-link ${isOpen ? "active" : ""}`}>
            <Link to="/services" onClick={toggleMenu}>Services</Link>
          </h2>
          <ul>
            {services.map((service) => (
              <li key={service.id}>{service.name}</li>
            ))}
          </ul>
        </div>

        <div className="comp-info">
          <h2 className = "service-link">
            <Link to="/contact" onClick={toggleMenu}>Contact</Link>
          </h2>
          <p>
            <strong>Phone:</strong> 72359327100
          </p>
          <p>
            <strong>Email:</strong> platinumcarspa9@gmail.com
          </p>
          <p>
            <strong>Address:</strong> N.h. No.48, Pipodra Bhambora Near Mahindra Show Room. Tal. Mangrol, Dist. Surat
          </p>
        </div>

        <div className="map-containers">
          <h2>Visit Our Store</h2>
          <iframe
            title="Location Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19350.13105147406!2d72.95855664320966!3d21.373930118342383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be03923c9c8b691%3A0x86012eb419025f9c!2sPlatinum%20Car%20Spa%20-%20Car%20%26%20Bike%20Detailing%2C%20PPF%20and%20Ceramic%20Coating%20Studio%2C%20Surat!5e1!3m2!1sen!2sin!4v1721964750179!5m2!1sen!2sin"
            width="100%"
            height="250"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <div className="social-media">
        <h3>Follow Us</h3>
        <div className="icon-container">
          <a href="https://www.instagram.com/platinum_car_spa?igsh=MWFxY2Vta3hmeDRjbQ==" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icons" />
          </a>
          <a href="https://www.youtube.com/@subirpatel9101" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="icons" />
          </a>
        </div>
      </div>

      <div className="copy-right">
        <p>&copy; 2024 Platinum Car Spa. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
