import React from 'react';
import { useEffect } from 'react';
import ServiceCard from '../components/ServiceCard';
import services from '../data/services';
import './Services.css';

const Services = () => {

  useEffect(()=>{
    document.title = "Services - Platinum Car Spa"
  })
  
  return (
    <div className="services-container">
      <h2>Our Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </div>
  );
}

export default Services;
