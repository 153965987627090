// src/pages/Home.js
import React from 'react';
import ImageSlider from '../components/ImageSlider'
import { useEffect } from 'react';
import './Home.css';

const importAll = (requireContext) => requireContext.keys().map(requireContext);
const images = importAll(require.context('../assets/images/recentWorks', false, /\.(png|jpe?g|svg)$/));

function Home() {

  useEffect(()=>{
    document.title ="Home - Platinum Car Spa"
  })
  return (
    <>
    <ImageSlider/>
    <div className="home-container">
      <section className="recent-work">
        <h2>RECENT WORKS</h2>
        <div className="work-gallery">
          {images.map((image, index) => (
            <div className="work-item" key={index}>
              <img 
                src={image} 
                alt={`Recent work ${index + 1}`} 
              />
            </div>
          ))}
        </div>
      </section>
    </div>
    </>
  );
}

export default Home;
