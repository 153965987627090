import React from 'react';
import './ServiceCard.css';

const ServiceCard = ({ service }) => {
  return (
    <div className="service-card">
      <img src={service.image} alt={service.name} className="service-image" />
      <h3>{service.name}</h3>
      <p>{service.description}</p>
      <p className="service-price">{service.price}</p>
    </div>
  );
}

export default ServiceCard;
