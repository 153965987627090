const services = [
    {
      name: "Bike Wash & Car Wash",
      description: "Comprehensive exterior wash to make your car shine.",
      //price: " Starting ₹ 100 - ₹ 400",
      image: require('../assets/images/services/car-wash.jpg'),
    },
    {
      name: "Interior Cleaning",
      description: "Deep cleaning of your car's interior for a fresh look.",
     // price: "₹ 1,500 - ₹ 2,000",
      image: require('../assets/images/services/interior-cleaning.jpg'),
    },
    {
      name: "Exterior Foam Wash (Vacuum + Dressing)",
      description: "Premium foam wash with vacuum and dressing.",
      //price: "₹ 300 - ₹ 400",
      image: require('../assets/images/services/exterior-foam-wash.jpg'),
    },
    {
      name: "Premium Exterior Foam Wash",
      description: "Top-notch exterior wash for a pristine look.",
      //price: "₹ 500 - ₹ 800",
      image: require('../assets/images/services/premium-foam-wash.jpg'),
    },
    {
      name: "Glass Treatment",
      description: "Glass Treatment enhances visibility by repelling water, dirt, and contaminants, making it easier to clean and improving safety in various weather conditions.",
      //price: "₹ 15,000",
      image: require('../assets/images/services/glass-treatment.jpg'),
    },
    {
      name: "Ceramic Coating Services",
      description: "Protect your car's paint with our ceramic coating.",
      //price: " Starting ₹ 12,000",
      image: require('../assets/images/services/ceramic-coating.jpg'),
    },
    {
      name: "Regural Bike Complete Detailing",
      description: "Full detailing service for your bike.",
      //price: "₹ 2,500 Ownwards",
      image: require('../assets/images/services/regular-detailing.jpg'),
    },
    {
      name: "Supper Bike Complete Detailing",
      description: "Full detailing service for your bike.",
      //price: "₹ 3,000 Ownwards",
      image: require('../assets/images/services/bike-detailing.jpg'),
    },
    {
      name: "Paint Protection Film (PPF)",
      description: "Ultimate protection for your car's paint.",
      //price: "₹ 95,000 - ₹ 1,10,000",
      image: require('../assets/images/services/ppf.jpg'),
    },
    {
      name: "Ceramic Coating 10H",
      description: "Ceramic Coating 10H is a high-hardness protective layer that enhances a vehicle's paint durability, offering superior scratch resistance and a glossy finish.",
      //price: "₹ 12,000 - ₹ 15,000",
      image: require('../assets/images/services/ceramic-10h.jpg'),
    },
    {
      name: "Graphene Coating",
      description: "Graphene Coating is an advanced car surface protection that provides excellent hydrophobic properties and enhanced durability against scratches and contaminants.",
      //price: "₹ 18,000 - ₹ 25,000",
      image: require('../assets/images/services/Graphene-Coating.jpg'),
    },
    {
      name: "Garware PPF 180MM",
      description: "Garware PPF 180MM is a premium paint protection film designed to safeguard vehicle surfaces from scratches, chips, and environmental damage while maintaining a high gloss finish.",
      //price: "₹ 95,000",
      image: require('../assets/images/services/garware-ppf180mm.jpg'),
    },
    {
      name: "Garware PPF 200MM",
      description: "Garware PPF 200MM is a high-quality paint protection film that offers enhanced protection against scratches, chips, and environmental factors, preserving the vehicle's paint and finish.",
      //price: "₹ 1,10,000",
      image: require('../assets/images/services/garware-ppf200mm.jpg'),
    }
  ];
  
  export default services;
  