import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

function Contact() {
  useEffect(() => {
    document.title = "Contact Us - Platinum Car Spa";
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4gvtjnm', 'template_nur1ud4', e.target, 'Hsst5d3G6iGu0Lbkb')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send the message, please try again.');
      });

    e.target.reset(); // Reset form fields after submission
  };

  return (
    <div className="contact-container">
      <div className="contact-main">
        <div className="company-info">
          <h1>Contact Us</h1>
          <p><strong>Phone:</strong> 72359327100</p>
          <p><strong>Email:</strong> platinumcarspa9@gmail.com</p>
          <p><strong>Address:</strong> N.h. No.48, Pipodra Bhambora Near Mahindra Show Room. Tal. Mangrol, Dist. Surat</p>
        </div>
        <div className="contact-form">
          <h2>Get in Touch</h2>
          <form onSubmit={sendEmail}>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="phone">Phone</label>
            <input type="text" id="phone" name="phone" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="map-container">
        <h2>Visit Our Store</h2>
        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19350.13105147406!2d72.95855664320966!3d21.373930118342383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be03923c9c8b691%3A0x86012eb419025f9c!2sPlatinum%20Car%20Spa%20-%20Car%20%26%20Bike%20Detailing%2C%20PPF%20and%20Ceramic%20Coating%20Studio%2C%20Surat!5e1!3m2!1sen!2sin!4v1721964750179!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
